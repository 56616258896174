<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>课程管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/CourseClassFicationList' }">课包列表</el-breadcrumb-item>
            <el-breadcrumb-item>{{CourseClassFicationInfo.CourseClassFicationName}}课程</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="30">
                <el-col :span="8">
                    <!--搜索-->
                    <el-input placeholder="请输入课程名称" v-model="QueryInfo.Name" clearable @clear="GetCourseByCourseClassFicationID"> 
                        <el-button slot="append" icon="el-icon-search" @click="GetCourseByCourseClassFicationID"></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="ShowAddCourseDialog">课程调剂</el-button>
                </el-col>
            </el-row>
            <el-table :data="CourseList" stripe>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="名称" prop="name"></el-table-column>
                <el-table-column label="描述" prop="description"></el-table-column>
            </el-table>
            <el-pagination
                @size-change="HandleSizeChange"
                @current-change="HandleCurrentChange"
                :current-page="QueryInfo.Start"
                :page-sizes="[1,2,5,10]"
                :page-size="QueryInfo.Count"
                layout="total, sizes, prev, pager, next, jumper"
                :total="CourseTotal">
            </el-pagination>
        </el-card>

        <el-dialog title="课程调剂" :visible.sync="AddCourseDialogVisible" width="40%" @close="AddCourseDialogVisible = false" :close-on-click-modal="false"> 
            <!-- <div style="height: 50vh; overflow: auto;display: inline;"> -->
            <div style="height: 50vh; overflow: auto;">
                <el-transfer class="T_CourseTransfer" v-model="SelectCourseList" 
                :titles="['可选课程', '已选课程']"
                :button-texts="['到左边', '到右边']"
                :data="AllCourseList" 
                :props="DefaultParams"></el-transfer>
            </div>
            <!-- </div> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="AddCourseDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="ModifyCourseClassFicationCourses">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import {  Transfer } from 'element-ui';
Vue.use(Transfer )
export default {
    data(){
        return{
            CourseClassFicationInfo:{
                CourseClassFicationName:'',
                CourseClassFicationID:0
            },
            QueryInfo:{
                Name:'',
                Start:1,
                Count:10,
                CourseClassFicationID:0
            },
            CourseTotal:0,
            CourseList:[],
            AddCourseDialogVisible:false,
            AllCourseList:[],
            DefaultParams:{
                key: 'id',
                label: 'name'
            }, 
            ModifyForm:{
                CourseClassFicationID:0,
                Courses:[]
            },
            SelectCourseList:[]
        }
    },
    created(){
        this.InitCourseClassFication()
        this.GetCourseByCourseClassFicationID()
    },
    methods:{
        InitCourseClassFication(){
            this.CourseClassFicationInfo.CourseClassFicationName = sessionStorage.getItem('courseclassname')
            this.CourseClassFicationInfo.CourseClassFicationID = sessionStorage.getItem('courseclassid')
            this.QueryInfo.CourseClassFicationID = this.CourseClassFicationInfo.CourseClassFicationID
            this.ModifyForm.CourseClassFicationID = parseInt(this.CourseClassFicationInfo.CourseClassFicationID)
        },
        async GetCourseByCourseClassFicationID(){
            const {data:res} = await this.$http.get('Course/v1/CoursesQueryByCourseClassFicationID',{params: this.QueryInfo})
            if(res.code !== 1){
                return this.$message.error('获取课程列表失败');
            }
            this.CourseList = res.data.courses
            this.CourseTotal = res.data.total
        },
        HandleSizeChange(count){
            this.QueryInfo.Count = count
            this.GetCourseByCourseClassFicationID()
        },
        HandleCurrentChange(start){
            this.QueryInfo.Start = start
            this.GetCourseByCourseClassFicationID()
        },
        ShowAddCourseDialog(){
            this.GetAllCourses()
            this.SelectCourseList.length = 0
            this.CourseList.forEach(element => {
                this.SelectCourseList.push(element.id)
            });
            this.AddCourseDialogVisible = true
        },
        async GetAllCourses(){
            const {data:res} = await this.$http.get('Course/v1/Courses')
            if(res.code !== 1){
                return this.$message.error('获取课程列表失败');
            }
            this.AllCourseList = res.data
        },
        async ModifyCourseClassFicationCourses(){
            this.ModifyForm.Courses = this.SelectCourseList
            // console.log(this.ModifyForm)
            const {data:res} = await this.$http.put('Course/v1/CourseByCourseClassFication',this.ModifyForm)
            if(res.code !== 1){
                return this.$message.error('获取课程列表失败');
            }
            // this.AddCourseDialogVisible = false
            this.$message.success("修改课程列表成功")
            this.GetCourseByCourseClassFicationID()
        }
    }
}
</script>

<style lang="less" scoped>
.T_CourseTransfer{
    text-align: left;
    display: inline;
}
</style>